/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const FLEETBASE_LOGIN = 'FLEETBASE_LOGIN';
export const FLEETBASE_LOGIN_SUCCESS = 'FLEETBASE_LOGIN_SUCCESS';
export const FLEETBASE_LOGIN_ERROR = 'FLEETBASE_LOGIN_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

// DASHBOARD
export const GET_VEHICLE_DATA = 'GET_VEHICLE_DATA';
export const GET_VEHICLE_DATA_SUCCESS = 'GET_VEHICLE_DATA_SUCCESS';
export const GET_VEHICLE_DATA_ERROR = 'GET_VEHICLE_DATA_ERROR';
export const MAKER_LIST = 'MAKER';
export const MODEL_LIST = 'MODEL';
export const VARIENT_LIST = 'VARIENT';
export const VEHICLE_LIST = 'VEHICLE_LIST';
export const VEHICLE_DETAILS = 'VEHICLE_DETAILS';
export const GET_GLOBAL_EV_DATA = 'GET_GLOBAL_EV_DATA';
export const GET_GLOBAL_EV_DATA_SUCCESS = 'GET_GLOBAL_EV_DATA_SUCCESS';
export const GET_GLOBAL_EV_DATA_ERROR = 'GET_GLOBAL_EV_DATA_ERROR';
export const GET_CONSOLIDATED_LOCATION = 'GET_CONSOLIDATED_LOCATION';
export const GET_CONSOLIDATED_LOCATION_SUCCESS = 'GET_CONSOLIDATED_LOCATION_SUCCESS';
export const GET_CONSOLIDATED_LOCATION_ERROR = 'GET_CONSOLIDATED_LOCATION_ERROR';
export const GET_CONSOLIDATED_VEHICLE_DATA = 'CONSOLIDATED_VEHICLE_DATA';
export const GET_CONSOLIDATED_VEHICLE_DATA_SUCCESS = 'CONSOLIDATED_VEHICLE_DATA_SUCCESS';
export const GET_CONSOLIDATED_VEHICLE_DATA_ERROR = 'CONSOLIDATED_VEHICLE_DATA_ERROR';
export const GET_VEHICLE_LIST = 'GET_VEHICLE_LIST';
export const GET_VEHICLE_LIST_SUCCESS = 'GET_VEHICLE_LIST_SUCCESS';
export const GET_VEHICLE_LIST_ERROR = 'GET_VEHICLE_LIST_ERROR';
export const GET_INDIVIDUAL_VEHICLE_DATA = 'GET_INDIVIDUAL_VEHICLE_DATA';
export const GET_INDIVIDUAL_VEHICLE_DATA_SUCCESS = 'GET_INDIVIDUAL_VEHICLE_DATA_SUCCESS';
export const GET_INDIVIDUAL_VEHICLE_DATA_ERROR = 'GET_INDIVIDUAL_VEHICLE_DATA_ERROR';
export const GET_CONSOLIDATED_DISTANCE_AND_CO2 = 'GET_CONSOLIDATED_DISTANCE_AND_CO2';
export const GET_CONSOLIDATED_DISTANCE_AND_CO2_SUCCESS = 'GET_CONSOLIDATED_DISTANCE_AND_CO2_SUCCESS';
export const GET_CONSOLIDATED_DISTANCE_AND_CO2_ERROR = 'GET_CONSOLIDATED_DISTANCE_AND_CO2_ERROR';
export const GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE = 'GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE';
export const GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_SUCCESS = 'GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_SUCCESS';
export const GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_ERROR = 'GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_ERROR';

// Journey
export const GET_JOURNEY_DETAILS = 'GET_JOURNEY_DETAILS';
export const GET_JOURNEY_DETAILS_SUCCESS = 'GET_JOURNEY_DETAILS_SUCCESS';
export const GET_JOURNEY_DETAILS_ERROR = 'GET_JOURNEY_DETAILS_ERROR';
export const JOURNEY_STEPS = 'JOURNEY_STEPS';
export const STEP_FIELDS = 'STEP_FIELDS';
export const STEP_INDEX = 'STEP_INDEX';
export const JOURNEY_BASIC_INFO = 'JOURNEY_BASIC_INFO';
export const JOURNEY_REQUIREMENTS = 'JOURNEY_REQUIREMENTS';
export const JOURNEY_BUSINESS_INFO = 'JOURNEY_BUSINESS_INFO';
export const JOURNEY_BUSINESS_KYC = 'JOURNEY_BUSINESS_KYC';
export const SUBMIT_JOURNEY_FORM = 'SUBMIT_JOURNEY_FORM';
export const SUBMIT_JOURNEY_FORM_SUCCESS = 'SUBMIT_JOURNEY_FORM_SUCCESS';
export const SUBMIT_JOURNEY_FORM_ERROR = 'SUBMIT_JOURNEY_FORM_ERROR';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR';
export const SUBMIT_PRODUCTS = 'SUBMIT_PRODUCTS';
export const SUBMIT_PRODUCTS_SUCCESS = 'SUBMIT_PRODUCTS_SUCCESS';
export const SUBMIT_PRODUCTS_ERROR = 'SUBMIT_PRODUCTS_ERROR';
export const CAMPAIGN_DATA = 'CAMPAIGN_DATA';
export const GET_BRE_RESULT = 'GET_BRE_RESULT';
export const GET_BRE_RESULT_SUCCESS = 'GET_BRE_RESULT_SUCCESS';
export const GET_BRE_RESULT_ERROR = 'GET_BRE_RESULT_ERROR';
export const GET_APPLICATION_SUMMARY = 'GET_APPLICATION_SUMMARY';
export const GET_APPLICATION_SUMMARY_SUCCESS = 'GET_APPLICATION_SUMMARY_SUCCESS';
export const GET_APPLICATION_SUMMARY_ERROR = 'GET_APPLICATION_SUMMARY_ERROR';
export const GET_BANK_LIST = 'GET_BANK_LIST';
export const GET_BANK_LIST_SUCCESS = 'GET_BANK_LIST_SUCCESS';
export const GET_BANK_LIST_ERROR = 'GET_BANK_LIST_ERROR';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_ERROR = 'GET_CITY_LIST_ERROR';


//Calculator
export const GET_STATE_LIST = 'GET_STATE_LIST';
export const GET_STATE_LIST_SUCCESS = 'GET_STATE_LIST_SUCCESS';
export const GET_STATE_LIST_ERROR = 'GET_STATE_LIST_ERROR';
export const GET_EV_DETAILS_ON_TYPE = 'GET_EV_DETAILS_ON_TYPE';
export const GET_EV_DETAILS_ON_TYPE_SUCCESS = 'GET_EV_DETAILS_ON_TYPE_SUCCESS';
export const GET_EV_DETAILS_ON_TYPE_ERROR = 'GET_EV_DETAILS_ON_TYPE_ERROR';



