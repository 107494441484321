import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import journeySaga from './journey/saga';
import dashboardSaga from './dashboard/saga';
import calcSaga from './calculator/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    journeySaga(),
    dashboardSaga(),
    calcSaga()
  ]);
}
